<template>
  <div>
    <!-- Breadcrumbs -->
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left mb-0" v-text="$t('students')" />
          </div>
        </div>
      </div>
    </div>
    <!-- Filters -->
    <shared-student-filters
      :name-filter.sync="filter.name"
      :email-filter.sync="filter.email"
      @click="loadData"
    />

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="mb-0"
        >

          <!-- Column: id -->
          <template #cell(id)="data">
            <span
              class="align-middle ml-50"
              v-text="data.item.count"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-button
              type="submit"
              variant="outline-primary"
              @click.prevent="$router.push({name: 'students.info', params: {id: data.item.id}})"
            >
              <span class="align-middle">{{ $t('view_details') }}</span>
            </b-button>
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>

import {
  BCard,
  BTable,
  BButton,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Student from '@/models/Student'
import SharedStudentFilters from '@/views/Shared/Student/Filter.vue'

export default {
  name: 'Student',
  components: {
    SharedStudentFilters,
    BCard,
    BTable,
    BButton,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      fields: [
        'id',
        'name',
        'email',
        'remaining_load',
        'remaining_schedules',
        'actions',
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : '',
        email: typeof this.$route.query.email !== 'undefined' ? this.$route.query.email : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      searchParams.page = page
      Object.keys(this.filter).forEach(e => {
        if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Student.list({ ...searchParams, role: 'admin' })
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.loading = false
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.levels-checkbox-container .custom-checkbox, .times-checkbox-container .custom-checkbox {
  margin-bottom: 5px;
}
</style>
